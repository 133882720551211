import { render, staticRenderFns } from "./UpdateOrder.vue?vue&type=template&id=94071844&scoped=true&"
import script from "./UpdateOrder.vue?vue&type=script&lang=js&"
export * from "./UpdateOrder.vue?vue&type=script&lang=js&"
import style0 from "./UpdateOrder.vue?vue&type=style&index=0&id=94071844&prod&scoped=true&lang=css&"
import style1 from "./UpdateOrder.vue?vue&type=style&index=1&id=94071844&prod&lang=css&"
import style2 from "./UpdateOrder.vue?vue&type=style&index=2&id=94071844&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94071844",
  null
  
)

export default component.exports